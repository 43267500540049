import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

import './MyriadPanel.css';

const MyriadPanel = ({ isOpen, togglePanel }) => {
  const [backgroundColor, setBackgroundColor] = useState('rgba(255, 14, 14, 0.306)');
  const [isMusicUnlocked, setMusicUnlocked] = useState(false); // State for music link icon toggle

  useEffect(() => {
    if (isOpen) {
      setBackgroundColor(getRandomColor());
    }
  }, [isOpen]);

  function getRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const a = 0.306;
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  const handleMusicClick = (e) => {
    e.preventDefault();
    const url = e.currentTarget.href;
    window.open(url, '_blank'); // Open the Spotify link in a new tab
    setMusicUnlocked(!isMusicUnlocked); // Toggle the icon
  };

  // Handler for other links to prevent link navigation
  const handleLinkClick = (e) => {
    e.preventDefault();
    // Additional logic can be added here if needed
  };

  if (!isOpen) return null;

  const panelStyle = {
    backgroundColor: backgroundColor,
  };

  return (
    <div className="myriad-panel" style={panelStyle}>

      <a href="http://myriad.aethereal.nexus" className="myriad-panel-link" onClick={handleLinkClick}>
        <span>Myriad</span>
        <FontAwesomeIcon icon={faLock} /> 
      </a>
      <a href="http://etheric-archives.aethereal.nexus" className="myriad-panel-link" onClick={handleLinkClick}>
        <span>Archives</span>
        <FontAwesomeIcon icon={faLock} /> 
      </a>
      <a href="http://cosmic-conduits.aethereal.nexus" className="myriad-panel-link" onClick={handleLinkClick}>
        <span>Codex</span>
        <FontAwesomeIcon icon={faLock} /> 
      </a>
      <a href="https://open.spotify.com/artist/43CB8o7WWI6B5YXLnScEDa?si=ODfl7Tw7RJufpjYBNHWaGA" className="myriad-panel-link" onClick={handleMusicClick}>
        <span>Music</span>
        <FontAwesomeIcon icon={isMusicUnlocked ? faUnlock : faLock} /> 
      </a>

      <div className="nav-circle" onClick={togglePanel}></div>
      
      <span style={{ color: 'rgba(255, 255, 255)', fontWeight: 'bold'}}>X</span>
    </div>
  );
};

MyriadPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  togglePanel: PropTypes.func.isRequired
};

export default MyriadPanel;
