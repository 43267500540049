import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact">
      <a href="mailto:x@aethereal.nexus" className="vertical-text">CONTACT</a>
    </div>
  );
};

export default Contact;
