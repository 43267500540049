// src/pages/navcircles/cern.js
import React from 'react';
import BackDot from '../../components/backdot/BackDot';
import Contact from '../../components/contact/Contact';
import './Cern.css';

const Cern = () => {
  return (
    <div className="cern-container">
      <div className="contact-background">
        <Contact />
      </div>

      <div className="cern-center">
        <h1 className="cern-title">CERN</h1>
        <p className="cern-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat nulla et urna convallis, at luctus sapien molestie.
        </p>
        <h2 className="cern-subtitle">Here&apos;s a glimpse of what happened at a technical level on October 12, 1993, when SLAC brought the first web server outside of Europe and North America online:</h2>
        
      </div>
      <img
        src="https://source.unsplash.com/random"
        alt="Centerpiece"
        className="center-image"
      />
      <div className="geometric-pattern"></div>
      <BackDot />
    </div>
  );
};

export default Cern;
