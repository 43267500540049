import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './components/contact/Contact'; 
import ToggleCircle from './components/panel/ToggleCircle'; 
import NavCircles from './components/nav-circles/NavCircles'; 
import MyriadPanel from './components/panel/MyriadPanel'; 
import FullScreenSlash from './components/slash/FullScreenSlash'; 
import Cern from './pages/navcircles/cern';
import Route1 from './pages/navcircles/route1';
import Route2 from './pages/navcircles/route2';

import './App.css';

const App = () => {
  const [isMyriadPanelOpen, setIsMyriadPanelOpen] = useState(false);

  const toggleMyriadPanel = () => {
    setIsMyriadPanelOpen(!isMyriadPanelOpen);
  };

  return (
    <Router>
      <div className="app">
        <NavCircles />
        <Routes>
          <Route path="/route1" element={<Route1 />} />
          <Route path="/cern" element={<Cern />} />
          <Route path="/route2" element={<Route2 />} />
          <Route path="/" element={
            <>
              <Contact />
              <FullScreenSlash />
              <img src="https://source.unsplash.com/random" alt="Centerpiece" className="center-image" />
              <ToggleCircle togglePanel={toggleMyriadPanel} />
              <MyriadPanel isOpen={isMyriadPanelOpen} togglePanel={toggleMyriadPanel} />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
