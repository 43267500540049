// src/pages/navcircles/Cern.js
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import BackDot from '../../components/backdot/BackDot';
import FullScreenSlash from '../../components/slash/FullScreenSlash';
import MyriadPanel from '../../components/panel/MyriadPanel'; // Correct import
import './Cern.css';

const popularStories = [
  { id: 1, title: "WWW", image: require('../navcircles/imgs/www.jpg'), link: "/www" },
  { id: 2, title: "Rune Maps", image: require('../navcircles/imgs/www.jpg'), link: "/story2" },
  { id: 3, title: "Network Explorers", image: require('../navcircles/imgs/www.jpg'), link: "/story3" }
];

// Function to duplicate items for infinite scrolling
const duplicateItemsForInfiniteScroll = (stories) => [...stories, ...stories];

// Component for displaying a section of stories
const StorySection = ({ title, stories }) => {
  const cardsContainerRef = useRef(null);

  useEffect(() => {
    const container = cardsContainerRef.current;
    const items = container.children;
    const totalWidth = Array.from(items).reduce((total, item) => total + item.offsetWidth, 0);

    const smoothScroll = () => {
      if (container.scrollLeft >= totalWidth) {
        container.scrollLeft = 0;
      } else {
        container.scrollLeft += 1;
      }
    };

    const interval = setInterval(smoothScroll, 15);

    return () => clearInterval(interval);
  }, [stories]);

  const handleMouseEnter = (e) => {
    const storyCard = e.currentTarget;
    const storyContent = storyCard.querySelector('.story-content');
    const backgroundImage = storyCard.style.backgroundImage;

    storyContent.style.backgroundImage = backgroundImage;
    storyContent.style.backgroundSize = 'cover';
    storyContent.style.backgroundPosition = 'center';
  };

  const handleMouseLeave = (e) => {
    const storyContent = e.currentTarget.querySelector('.story-content');
    storyContent.style.backgroundImage = 'none';
  };

  return (
    <div className="story-section mb-5">
      <h2 className="section-title">{title}</h2>
      <div className="story-cards-container" ref={cardsContainerRef}>
        {duplicateItemsForInfiniteScroll(stories).map((story, index) => (
          <a
            href={story.link}
            key={`${story.id}-${index}`}
            className="story-card-link text-decoration-none"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="card story-card shadow-sm" style={{ backgroundImage: `url(${story.image})` }}>
              <div className="top-dots">
                <div className="dot" title="Description 1"></div>
                <div className="dot center-dot" title="Description 2"></div>
                <div className="dot" title="Description 3"></div>
              </div>
              <div className="card-body story-content">
                <h5 className="card-title text-light">{story.title}</h5>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

// PropTypes validation for StorySection
StorySection.propTypes = {
  title: PropTypes.string.isRequired,
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
};

const ToggleCircle = ({ togglePanel }) => (
  <div className="bottom-right-char" onClick={togglePanel} style={{ cursor: "pointer" }}>/</div>
);

ToggleCircle.propTypes = {
  togglePanel: PropTypes.func.isRequired
};

const Cern = () => {
  const [isMyriadPanelOpen, setIsMyriadPanelOpen] = useState(false);

  const toggleMyriadPanel = () => {
    setIsMyriadPanelOpen(!isMyriadPanelOpen);
  };

  const handleClick = () => {
    // Assuming "main page" is the root URL
    window.location.href = "/";
  };

  return (
    <div className="container-fluid">
      <BackDot />
      <div onClick={handleClick} style={{ cursor: "pointer" }}>
        <FullScreenSlash />
      </div>
      <ToggleCircle togglePanel={toggleMyriadPanel} />
      <MyriadPanel isOpen={isMyriadPanelOpen} togglePanel={toggleMyriadPanel} />
      <h1>Have fun!</h1>
      <StorySection title="X" stories={popularStories} />
      {/* <StorySection title="Trending Now" stories={trendingStories} />
      <StorySection title="Picks for You" stories={picksForYou} /> */}
    </div>
  );
};

export default Cern;
