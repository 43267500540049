// PROJETS\AEN-PROJECTS\aethereal-nexus\src\index.js

import React from 'react';
import ReactDOM from 'react-dom/client'; 
import './index.css'; 

import reportWebVitals from './reportWebVitals';
// import Main from './pages/Main';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Main /> */}
    <App />
  </React.StrictMode>
);

reportWebVitals();
