import React from 'react';
import './FullScreenSlash.css'; // Import the CSS file for styling

const FullScreenSlash = () => {
  return (
    <div className="full-screen-slash">
      <a href="https://art.aethereal.nexus" target="_blank" rel="noopener noreferrer">
        \
      </a>
    </div>
  );
};

export default FullScreenSlash;
