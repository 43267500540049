// src/components/backdot/BackDot.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './BackDot.css';

const BackDot = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [tooltipList, setTooltipList] = useState([]);
  const [hasNavigated, setHasNavigated] = useState(false);
  const navigate = useNavigate();
  const tooltipId = useRef(0);

  const goBack = () => {
    setClickCount((prev) => prev + 1);
    tooltipId.current += 1;
    setTooltipList((prev) => [
      ...prev,
      { id: tooltipId.current, position: 0 }
    ]);
    setShowTooltip(true);

    // Ensure navigation only happens once
    if (!hasNavigated) {
      setHasNavigated(true);
      setTimeout(() => {
        navigate(-1);
      }, 5000);
    }
  };

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  // Animate the "|||" movement
  useEffect(() => {
    if (tooltipList.length > 0) {
      const interval = setInterval(() => {
        setTooltipList((tooltips) =>
          tooltips
            .map((tooltip) => ({
              ...tooltip,
              position: tooltip.position + 10
            }))
            .filter((tooltip) => tooltip.position < 100)
        );
      }, 100);

      return () => clearInterval(interval);
    }
  }, [tooltipList]);



  return (
    <div className="back-dot-container" onClick={goBack} onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip}>
      <div className="back-dot"></div>
      <div className="click-count">{clickCount}</div>
      {tooltipList.map((tooltip) => (
        <div
          key={tooltip.id}
          className="back-tooltip"
          style={{ bottom: `${20 + tooltip.position}px` }}
        >
          |||
        </div>
      ))}
    </div>
  );
};

export default BackDot;