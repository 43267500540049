import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './NavCircles.css'; 

const NavCircles = () => {
  // Function to handle the circle click for animation or any other purpose without navigation
  const handleCircleClick = (event) => {
    // Prevent any default action if there was one
    event.preventDefault();
    // You can add any logic here to animate the circle or log something, etc.
    // For demonstration, the CSS :active selector is used for a simple effect.
  };

  return (
    <div className="circle-container">
      {/* Center circle navigates to the "Cern" page */}
      {/* Outer circles (assuming different routes) */}
      <Link to="/route1" className="nav-circle" onMouseDown={handleCircleClick} />
      <Link to="/cern" className="nav-circle center" onMouseDown={handleCircleClick} />
      <Link to="/route2" className="nav-circle" onMouseDown={handleCircleClick} />
    </div>
  );
};

export default NavCircles;
