// src/pages/navcircles/route2.js
import React from 'react';
import BackDot from '../../components/backdot/BackDot';
import './Route2.css';

const Route2 = () => {
  return (
    <div className="route2-container">
      <div className="route2-center">
        <h1 className="route2-title">Route 2</h1>
        <p className="route2-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eu ex ut sapien tristique malesuada.
        </p>
      </div>
      <div className="geometric-pattern"></div>
      <BackDot />
    </div>
  );
};

export default Route2;
