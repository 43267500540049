import React from 'react';
import PropTypes from 'prop-types'; // Import prop-types package
import './ToggleCircle.css';

const ToggleCircle = ({ togglePanel }) => {
  return (
    <div className="bottom-right-char" onClick={togglePanel}>/</div>
  );
};

ToggleCircle.propTypes = {
  togglePanel: PropTypes.func.isRequired // Validate togglePanel prop as a required function
};

export default ToggleCircle;
